.cta {
    text-align: center;
    padding: 50px;

}

.cta-button {
    font-size: 1.5rem;
    padding: 15px 30px;
    margin: 0 20px;
    color: white;
    background-color: #ffd32a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    transform: scale(1.1);
}

.cta-button.blue {
    background-color: #2B5278;
}

.cta-button.black {
    background-color: black;
}