body {
  background: #555;
  background-image: url('./img/bg.gif');
  /* path to your background image */
  background-repeat: repeat;
  /* This makes the image repeat */
  background-size: 200px 200px;
  /* Image will retain its original size */
  background-position: top left;
  /* Image starts at the top-left */
  min-height: 100vh;
  /* Ensure it covers the entire viewport */
}

.App {
  width: 1024px;
  margin: 60px auto;
  text-align: center;
  font-family: 'Arial', sans-serif;
  background-color: #fff;
  color: #333;
  background: #f39c12;
  border: 40px solid #feca57;

}

.logo {
  margin: 25px 0;
}

h1,
h2 {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}