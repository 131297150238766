.description {
    padding: 40px;
    text-align: center;
    color: black;
}

.description h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.description p {
    font-size: 1.2rem;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.5;
}